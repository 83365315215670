<template>
  <div
    id="user-profile"
    class="pb-4"
  >
    <!-- strat header -->
    <b-card
      class="profile-header mb-2"
      img-src="https://upload.cc/i1/2021/02/13/i5H9DR.jpg"
      img-top
      body-class="p-0"
    >
      <div
        class="position-absolute"
        style="right: 15px; top: 15px"
      >
        <b-button
          variant="gradient-primary"
          @click="
            $router.push({
              name: 'reserve-order-page',
              params: { botId: $route.params.botId },
            })
          "
        >
          預約紀錄
        </b-button>
      </div>

      <!-- profile picture -->
      <div class="position-relative">
        <div class="profile-img-container d-flex align-items-center">
          <div class="profile-img">
            <b-img
              src="https://upload.cc/i1/2021/02/13/tvlCei.jpg"
              rounded
              fluid
            />
          </div>
          <!-- profile title -->
          <div class="profile-title ml-1">
            <h2 class="overlay-white font-weight-bold">
              SG 維恩的秘密花園
            </h2>
            <p class="font-weight-bold">
              台北店 & 台中店
            </p>
          </div>
          <!--/ profile title -->
        </div>
      </div>
      <!--/ profile picture -->

      <!-- profile navbar -->
      <div class="profile-header-nav">
        <b-navbar
          toggleable="md"
          type="light"
        >
          <!-- toggle button -->
          <b-navbar-toggle
            class="ml-auto"
            target="nav-text-collapse"
          >
            <feather-icon
              icon="AlignJustifyIcon"
              size="21"
            />
          </b-navbar-toggle>
          <!--/ toggle button -->

          <!-- collapse -->
          <b-collapse
            id="nav-text-collapse"
            is-nav
          >
            <b-tabs
              pills
              class="profile-tabs mt-1 mt-md-0"
              nav-class="mb-0"
            >
              <template #tabs-start>
                <b-nav-item
                  role="presentation"
                  active
                  class="font-weight-bold"
                >
                  <span class="d-none d-md-block">服務</span>
                  <feather-icon
                    icon="RssIcon"
                    class="d-block d-md-none"
                  />
                </b-nav-item>
              </template>

              <!-- edit buttons -->
              <template #tabs-end>
                <!-- <b-button variant="primary" class="ml-auto">
                  <feather-icon icon="EditIcon" class="d-block d-md-none" />
                  <span class="font-weight-bold d-none d-md-block">Edit</span>
                </b-button> -->
              </template>
              <!-- edit buttons -->
            </b-tabs>
          </b-collapse>
          <!--/ collapse -->
        </b-navbar>
      </div>
      <!--/ profile navbar -->
    </b-card>
    <!-- end header -->

    <router-view />
  </div>
</template>

<script>
import {
  BCard, BButton, BImg, BNavbar, BNavbarToggle, BCollapse, BTabs, BNavItem,
} from 'bootstrap-vue'

export default {
  components: {
    // Layouts
    BCard,
    BButton,
    BImg,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BTabs,
    BNavItem,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
.card-img-top {
  height: 200px;
  width: 100%;
  object-fit: cover;
  object-position: 100% 10%;
  opacity: 0.6;
}
</style>
